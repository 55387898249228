<template>
  <div class="d-flex justify-content-center align-items-center" v-if="value">
    <img
      class="avatar-image"
      :src="value?.image_url"
      alt=""
      v-if="value?.image_url"
    />
    <span class="text-gray-dark mx-2">
      {{ value?.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PartnerInfo",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
